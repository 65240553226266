<template>
	<v-app class="xiaomi">
		<transition name="fade-in-out" appear>
			<router-view></router-view>
		</transition>
	</v-app>
</template>
<script>
import { buyout } from 'js-mp';
import Home from '@/views/Home';
import {loadGTM} from "./cookie-bar";

export default {
	name: 'App',
	comments: {
		...buyout.components,
		Home
	},
  created() {
    loadGTM();
  }
};
</script>

<style></style>

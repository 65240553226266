import Vue from 'vue';
import VueRouter from 'vue-router';
import { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout/index';
import { MUTATION_TYPES } from 'js-mp/src/vue/store/buyout/_mutation-types';
import { ACTION_TYPES } from 'js-mp/src/vue/store/buyout/_action-types';
import { GETTER_TYPES } from 'js-mp/src/vue/store/buyout/_getter-types';
import {sleep} from "js-mp/src/js/buyout";
import { store } from '../main';

Vue.use(VueRouter);

const routes = [
	{
		path: '/podminky-programu',
		name: 'rules',
		component: () => import('../views/Rules')
	},
	{
		path: '/zpracovani-osobnich-udaju',
		name: 'privacyPolicy',
		component: () => import('../views/PrivacyPolicy')
	},
	{
		path: '/partneri',
		name: 'partners',
		component: () => import('../views/Partners')
	},
	{
		path: '/vykup/:userBuyoutCode',
		name: 'buyout',
		component: () => import('../views/Home'),
		beforeEnter: (to, from, next) => {
			if (!to.params.userBuyoutCode) next('/');
			if (store.getters[`${VUEX_BUYOUT.NAMESPACE}/${GETTER_TYPES.USER_BUYOUT_CODE}`] === to.params.userBuyoutCode){
				next();
      }
			else {
        store.dispatch(
          `${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.LOAD_BUYOUT}`
        );
				store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_BUYOUT_ONLINE}`,
          true
        );
        store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_REGISTRATION}`,
          false
        );
        store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_LOGGED_IN}`,
          true
        );
        store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_BUYOUT_LOAD_ERROR}`,
          null
        );
        store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_USER_BUYOUT_CODE}`,
          to.params.userBuyoutCode
        );
        store.dispatch(
          `${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.LOAD_STATE_TYPES}`
        );
        Vue.nextTick(async () => {
          await sleep(200);
          this.$scrollTo('#deviceSum');
        });
			}
			next();
		}
	},
	{
		path: '/:category?/:brand?/:model?/:color?/:quality?',
		name: 'home',
		component: () => import('../views/Home'),
		beforeEnter: (to, from, next) => {
			if (to.params.category && to.params.category === 'vykup') next('/');
			else {
				next();
			}
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

export default router;

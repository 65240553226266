export function loadGTM(gtmId='GTM-THMJ9CB') {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  // Improve ad click measurement quality (optional)
  gtag("set", "url_passthrough", true);
  // Further redact your ads data (optional)
  gtag("set", "ads_data_redaction", true);

  gtag('js', new Date());
  gtag('config', gtmId);

  ((w, d, s, l) => {
  w[l] = w[l] || [];
  w[l].push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
  f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer");
}

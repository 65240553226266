export const overrides = {
	cs: {
		home: {
			how_it_works: 'Jak to funguje',
			faq: 'Časté dotazy',
			contact: 'Kontakt',
			check_price: 'Spočítat',
			action_rules: 'Pravidla akce',
			partners_list: 'Seznam partnerů',
			partners_list_all: 'Seznam všech partnerů',
			privacy_policy: 'Ochrana informací',
			back_to_home: 'Zpět na hlavní stránku'
		},
		buyout: {
      search_for_device: "Vyhledat Vaše staré zařízení",
			thankYou: {
				onlineBuyout: {
					text: 'Potvrzení registrace bylo zasláno na Vaši e-mailovou adresu.'
				}
			},
      logRegSwapper:{
        register: "Registrace výkupu"
      }
		}
	}
};

import Vue from 'vue';
import App from './App.vue';
import './styles/entry.js';
import VueScrollTo from 'vue-scrollto';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import buyout_store, { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout/index';
import router from './router';
import { buyout } from 'js-mp';
import { config } from '@/config';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n';
import { overrides } from './language_overrides';
import { version } from '../package.json';
import Fragment from 'vue-fragment';
import "./cookie-bar";
import Vuex from 'vuex';

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(Vuetify);
Vue.use(VueI18n);
Vue.use(VueScrollTo);

if (
	process.env.NODE_ENV === 'production' ||
	process.env.NODE_ENV === 'staging'
) {
	Sentry.init({
		dsn: process.env.VUE_APP_SENTRY_DSN,
		release: 'xaomi' + version,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
		],
		tracesSampleRate: process.env.NODE_ENV === 'staging' ? 1.0 : 0.65,
    environment: process.env.NODE_ENV
	});
}

// override default config
// buyout.config = extend(buyout.config, config);

// override default language settings
const { vuetify, i18n } = buyout.plugins;

i18n.locale = config.language;

for (const lang in overrides) {
	i18n.mergeLocaleMessage(lang, overrides[lang]);
}

const NS = VUEX_BUYOUT.NAMESPACE;
let options = {
	modules: {}
};

options.modules[NS] = buyout_store;
export const store = new Vuex.Store(options);

buyout.utils.apiAxios.defaults.headers = {
	...buyout.utils.apiAxios.defaults.headers,
	'Accept-Language': config.language
};

buyout.store.state.contractForm.customer_data.phone_number =
	config.international_phone_prefix;
buyout.store.state.contractForm.customer_data.bank_code =
	config.default_bank_code;
buyout.store.state.contractForm.customer_data.bank_account_full_number = '';
buyout.store.state.campaignIdentifier = process.env.VUE_APP_CAMPAIGN_ID;

Vue.use(Fragment.Plugin);

new Vue({
  store,
	router,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app');

<template>
	<fragment>
		<header :class="['header', isMobile && isHamburgerOpen ? 'is-mobile' : '']">
			<div class="header__navWrapper">
				<img src="@/assets/images/svg/logo-vymenzaxiaomi-cz.svg" width="56" height="56">
				<nav class="header__nav">
					<div
						:class="['header__hamburger', isHamburgerOpen ? 'is-open' : '']"
						@click="isHamburgerOpen = !isHamburgerOpen"
					>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<v-btn
						text
						:ripple="false"
						@click="
							scrollTo('how_it_works');
							isHamburgerOpen = !isHamburgerOpen;
						"
					>
						{{ $t('home.how_it_works') }}
					</v-btn>
					<div class="divider"></div>
					<v-btn
						text
						:ripple="false"
						@click="
							scrollTo('frequently_asked_questions');
							isHamburgerOpen = !isHamburgerOpen;
						"
					>
						{{ $t('home.faq') }}
					</v-btn>
					<div class="divider"></div>
					<v-btn
						text
						:ripple="false"
						@click="
							scrollTo('contact');
							isHamburgerOpen = !isHamburgerOpen;
						"
					>
						{{ $t('home.contact') }}
					</v-btn>
				</nav>
			</div>
			<div class="header__claim">
        <div class="phones">
          <img src="@/assets/images/xiaomi13-vymenzaxiaomi-cz.png"
               srcset="@/assets/images/xiaomi13-vymenzaxiaomi-cz@2x.png 2x"
          >
        </div>
        <div class="desc text-center">
          <text-block identifier="mainClaim"/>
          <router-link :to="{ name: 'rules' }">
            {{ $t('home.action_rules') }}
          </router-link>
          <v-btn text :ripple="false" @click="scrollTo('new_registration')">
            {{ $t('home.check_price') }}
          </v-btn>
        </div>
			</div>
		</header>
		<main class="main">
			<text-block identifier="bonusPhones" />
			<div class="howItWorks__wrapper fullBgColor bgBlue">
				<div class="howItWorks__title">{{ $t('home.how_it_works') }}?</div>
				<how-it-works id="how_it_works" :show-title="true" />
			</div>
			<log-reg-swapper
				id="new_registration"
				class="logReg fullBgColor bgBlue--light"
			>
				<template #isNotRegistration>
					<online-buyout-only class="logReg__reg">
						<template #bonusPhoneTitle>
							Vyberte nově zakoupené Xiaomi
						</template>
						<template #privacyPolicyText>
							<p>
								Souhlasím se zpracováním osobních údajů. Více
								informací&nbsp;<router-link :to="{ name: 'privacyPolicy' }"
									>zde</router-link
								>.
							</p>
						</template>
						<template #consentToMarketingText>
							<p>
								Tímto uděluji souhlas společnostem Xiaomi Technology Czech s.r.o., se sídlem V parku 2336/22, 148 00 Praha 4, IČO: 11854006, DIČ: CZ11854006, společnost zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, sp. zn. C 355384 a Beryko s.r.o., se sídlem Na Roudné 1162/76, Plzeň, 301 00, IČO: 028 52 152, DIČ: CZ02852152, společnost zapsaná v obchodním rejstříku vedeném Krajským soudem v Plzni, sp. zn. C 29637, aby mi zasílaly personalizované reklamní a obchodní sdělení prostřednictvím elektronických prostředků o výrobcích, službách, akcích a kampaních společnosti Xiaomi (včetně nabídek a slev).
							</p>
						</template>
					</online-buyout-only>
				</template>
			</log-reg-swapper>
			<div class="partners">
				<div class="partners__title">{{ $t('home.partners_list') }}</div>
				<div class="partners__subtitle">
          <div>U kterých můžete zakoupit Xiaomi 13 Pro | 13 | 13 Lite s bonusem až <span class="no-wrap">3 000 Kč</span></div>
				</div>
				<partners
					type="list"
					base-color="#ff6709"
					:columns="27"
					hover-color="#071930"
					:only-main="true"
				/>
				<router-link
					@click.native="scrollTo('partners_title', 0)"
					class="mbtn mbtn--orangeInv"
					:to="{ name: 'partners' }"
					>{{ $t('home.partners_list_all') }}</router-link
				>
			</div>
			<div id="frequently_asked_questions" class="faq">
				<div class="faq__title">{{ $t('home.faq') }}</div>
				<faq />
			</div>
			<text-block id="contact" class="contact" identifier="contact" />
		</main>
		<footer class="footer fullBgColor bgBlue">
			<v-btn text :ripple="false" :to="{ name: 'privacyPolicy' }">{{
				$t('home.privacy_policy')
			}}</v-btn>
		</footer>
		<error-message></error-message>
	</fragment>
</template>
<script>
import {buyout} from 'js-mp';
import debounce from 'lodash/debounce';

import { sleep } from 'js-mp/src/js/buyout';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout';
import { GETTER_TYPES } from 'js-mp/src/vue/store/buyout/_getter-types';
import { MUTATION_TYPES } from 'js-mp/src/vue/store/buyout/_mutation-types';
import { ACTION_TYPES } from 'js-mp/src/vue/store/buyout/_action-types';

export default {
	name: 'HomeView',
	components: { ...buyout.components },
	data() {
		return {
			isHamburgerOpen: false,
			isMobile: false
		};
	},
  computed:{
    ...mapGetters(VUEX_BUYOUT.NAMESPACE, [
			GETTER_TYPES.IS_REGISTRATION,
      GETTER_TYPES.BUYOUT_LOAD_ERROR,
      GETTER_TYPES.GET_SELECTED_MODEL
		]),
    registration: {
			get() {
				return this[GETTER_TYPES.IS_REGISTRATION];
			},
			set(v) {
				this[MUTATION_TYPES.SET_IS_REGISTRATION](v);
			}
		}
  },
	async created() {
		if (!this.campaign) this[ACTION_TYPES.LOAD_CAMPAIGN]();
		window.addEventListener('resize', debounce(this.onResize, 250), {
			passive: false
		});
		this.onResize();
		await sleep(50);
		if (typeof this.$route.params.color != 'undefined') {
			await sleep(4000);
			await this.scrollTo('categorySelector', 500, 300);
		} else {
			if (typeof this.$route.params.category != 'undefined') {
				this.registration = true;
				await this.scrollTo('categorySelector', 500, -300);
			}
			if (typeof this.$route.params.userBuyoutCode != 'undefined') {
				this.registration = false;
				await sleep(500);
				this[GETTER_TYPES.BUYOUT_LOAD_ERROR]
					? await this.scrollTo('loginForm', 500, 0)
					: await sleep(500);
				await this.scrollTo('deviceSummary');
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', debounce(this.onResize, 250));
	},
	methods: {
    ...mapMutations(VUEX_BUYOUT.NAMESPACE, [
			MUTATION_TYPES.SET_IS_REGISTRATION,
		]),
		...mapActions(VUEX_BUYOUT.NAMESPACE, [
			ACTION_TYPES.LOAD_CAMPAIGN,
		]),
		async scrollTo(id, duration = 500, offset = -200) {
			await sleep(150);
			this.$scrollTo(`#${id}`, { offset: offset, duration });
		},
		async calculate() {
			if (this[GETTER_TYPES.GET_SELECTED_MODEL]()) await this.scrollTo('new_registration');
			this.registration = true;
		},
		onResize() {
			this.isMobile = window.innerWidth < 850;
			if (!this.isMobile && this.isHamburgerOpen) this.isHamburgerOpen = false;
		}
	}
};
</script>

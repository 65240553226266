import router from '@/router';
import { buyout } from 'js-mp';
import extend from 'lodash/extend';

const config_overrides = {
	isURLHandlingEnabled: true,
	currency: 'Kč',
	isOnlyOnlineBuyout: true,
	language: 'cs',
	international_phone_prefix: '+420',
	router: router,
	default_bank_code: '',
	privacy_policy: {
		text:
			'Souhlasím s pravidly programu „{X}“ a se zpracováním osobních údajů potřebných pro účast v programu.',
		link_title: 'Vyměň svoje staré zařízení za nové online',
		link: '/pravidla'
	},
  isParcelshopEnabled: true,
};

// override default config
buyout.config = extend(buyout.config, config_overrides);

export const config = buyout.config;
